<template id="card">
  <OperacionNoIniciada v-if="!$caja()" operacion="ventas" />

  <b-card v-else no-body>
    <b-card-body>
      <b-card-text>
        <b-tabs content-class="mt-1" align="center">
          <b-tab title="NUEVA VENTA" lazy>
            <hr />
            <PuntoVentaLayout tipo="vistaVenderdor"></PuntoVentaLayout>
          </b-tab>
          <b-tab title="LISTA DE VENTAS" lazy>
            <hr />
            <VentaVendedorLayout></VentaVendedorLayout>
          </b-tab>
        </b-tabs>
      </b-card-text>
    </b-card-body>
  </b-card>
</template>
  
<script>
import PuntoVentaLayout from "../layouts/PuntoVentaLayout.vue";
import VentaVendedorLayout from "../layouts/VentaVendedorLayout.vue";
import { BTabs, BTab, BCard, BCardText, BCardBody } from "bootstrap-vue";
import OperacionNoIniciada from "@/views/OperacionNoIniciada.vue";

export default {
  components: {
    BTabs,
    BTab,
    BCard,
    BCardText,
    PuntoVentaLayout,
    VentaVendedorLayout,
    BCardBody,
    OperacionNoIniciada,
  },
};
</script>

<style scoped>
.card-header {
  padding: 1rem 0rem 0rem 1rem !important;
}
</style>